@import '/src/theme.scss';

.Spinner {
  color: var(--mila-color-primary);
  padding: 40px;

  display: flex;
  justify-content: center;
}

.Spinner :global(.ant-spin-dot-item) {
  background-color: var(--mila-color-primary);
}
